import { userService } from '../services';
import baseMethods from './base';

const initialState = 
    { status: { loading: false,ready: false },
        usermin: {firstName:"",secondName:"",lastName:"",titel:"",email:"",email2:"",user_Img_URL:"",user_img:"",partnerID:"",language_fk:1,userGuid:""},
        useroverview:[{}],
        userfull : {
            userdata: {firstName:"",secondName:"",lastName:"",titel:"",userName:"",email:"",email2:"",user_Img_URL:"",partnerID:"",
                        tel1:"",tel2:"",address1_fk:-1,address2_fk:-1,address_acc_fk:-1,address_dev_fk:-1,use_address2:false,
                        use_address_acc:false,use_address_dev:false,language_fk: -1,bank_account_fk:-1,user_img:"",time_model_fk:0,time_start_saldo:0},
            userdataOld:"",
            status:{ loading: false,ready: false }
        },
        rights:{ status:{loading:false, ready:false},data:[]}
    };

export const users = {
    namespaced: true,
    state: initialState,
    actions: {
        getUserMinData({ commit,dispatch }) {                      
            
            commit('getUserMinRequest');
            return new Promise((resolve, reject) => {
                userService.getUserMinData()
                    .then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('getUserMinSuccess', response.data);
                                dispatch('maincontainer/setLoadingStatus', {type:"usermin",loaded:true},{root: true},{root: true}); 
                                resolve(response.data);
                            }
                            else
                            {
                                commit('getUserMinFail');
                                reject(response.statusText);
                            }
                        }
                    );
            })
        },

        // eslint-disable-next-line no-unused-vars
        existsUsername({ commit, }, username) {                      
            
            return new Promise((resolve, reject) => {
                userService.existsUsername(username)
                    .then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                resolve(response.data);
                            }
                            else
                            {
                                reject(response.statusText);
                            }
                        }
                    );
            })
        },

        // eslint-disable-next-line no-unused-vars
        getNextPartnerId({ commit, }, lastname) {                      
            
            return new Promise((resolve, reject) => {
                userService.getNextPartnerId(lastname)
                    .then(
                        response => {
                            if(baseMethods.IsSuccess(response))
                            {
                                resolve(response.data);
                            }
                            else
                            {
                                reject(response.statusText);
                            }
                        }
                    );
            })
        },        
        getUserOverviewData({ commit }) {                      

            commit('getUserOverviewDataRequest');
            return new Promise((resolve, reject) => {

             userService.getUserOverviewData()
                .then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getUserOverviewDataSuccess', response.data);
                            if(response.data != null && response.data.length > 0)
                                resolve(true);
                            else
                                resolve(false);
                        }
                        else
                        {
                            commit('getUserOverviewDataFail');
                            reject(response.statusText);
                        } 
                    }
                ); 
            })
        },
        // eslint-disable-next-line no-unused-vars
        deleteUser({commit}, parameters)
        {
            return new Promise((resolve, reject) => {
                
                userService.deleteUser(parameters.guid).then(
                    response => {
                        
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve({"guid":parameters.guid,"name":parameters.name,"requestOk":response.data});
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },        
        // eslint-disable-next-line no-unused-vars
        blockUser({commit}, parameters)
        {
            return new Promise((resolve, reject) => {
                
                userService.blockUser(parameters.guid).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            resolve({"guid":parameters.guid,"name":parameters.name,"requestOk":true});
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        unblockUser({commit}, payload)
        {
            return new Promise((resolve, reject) => {
                
                userService.unblockUser(payload.guid).then(
                    response => {
                        if(baseMethods.IsSuccess(response))
                        {
                            resolve({"guid":payload.guid,"name":payload.name,"requestOk":true});
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getUserOverviewDataSingle({ commit }, guid)
        {
            return new Promise((resolve, reject) => {
                
                userService.getUserOverviewDataSingle(guid).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getUserOverviewDataSingleSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },

        getUserFullData({ commit }, guid)
        {
            commit('getUserFullDataRequest');

            return new Promise((resolve, reject) => {
                
                userService.getUserFullData(guid).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getUserFullDataSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getUserFullDataFail');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getUserRoles({ commit })
        {
            commit('getUserRolesRequest');

            return new Promise((resolve, reject) => {
                
                userService.getUserRoles().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getUserRolesSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getUserRolesFail');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },        
        
        clearUserFullData({ commit })
        {
            commit('clearUserFullData');
        },
        updateUserFullData({ commit },payload)
        {
            commit('updateUserFullDataRequest');

            return new Promise((resolve, reject) => {
                
                userService.updateUserFullData(payload.UserData).then(
                    response => {
 
                        if(baseMethods.IsSuccess(response))
                        {
                            commit('updateUserFullDataSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('updateUserFullDataFail');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        newUser({ commit }, payload)
        {
            commit('newUserRequest');

            return new Promise((resolve, reject) => {
                
                userService.newUser(payload).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('newUserSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('newUserFail');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        newPartner({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                userService.newPartner(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        updateUserFullDataFirstName({ commit }, firstName)
        {
            commit('updateFullDataFirstName',firstName);
        },
        updateUserFullDataSecondName({ commit }, secondName)
        {
            commit('updateFullDataSecondName',secondName);
        },
        updateUserFullDataLastName({ commit }, lastName)
        {
            commit('updateFullDataLastName',lastName);
        },
        updateUserFullDataTitel({ commit }, titel)
        {
            commit('updateFullDataTitel',titel);
        },
        updateUserFullDataEmail({ commit }, email)
        {
            commit('updateFullDataEmail',email);
        },
        updateUserFullDataEmail2({ commit }, email)
        {
            commit('updateFullDataEmail2',email);
        },
        updateUserFullDataPartnerID({ commit }, partnerID)
        {
            commit('updateFullDataPartnerID',partnerID);
        },
        updateUserFullDataUserName({ commit }, userName)
        {
            commit('updateFullDataUserName',userName);
        },
        updateUserFullDataTel1({ commit }, tel1)
        {
            commit('updateFullDataTel1',tel1);
        },
        updateUserFullDataTel2({ commit }, tel2)
        {
            commit('updateFullDataTel2',tel2);
        },
        updateUserFullDataAddress1_fk({ commit }, fk)
        {
            commit('updateFullDataAddress1_fk',fk);
        },
        updateUserFullDataAddress2_fk({ commit }, fk)
        {
            commit('updateFullDataAddress2_fk',fk);
        },
        updateUserFullDataAddress_acc_fk({ commit }, fk)
        {
            commit('updateFullDataAddress_acc_fk',fk);
        },
        updateUserFullDataAddress_dev_fk({ commit }, fk)
        {
            commit('updateFullDataAddress_dev_fk',fk);
        },
        updateUserFullDataUse_address2({ commit }, fk)
        {
            commit('updateFullDataUse_address2',fk);
        },
        updateUserFullDataUse_address_acc({ commit }, fk)
        {
            commit('updateFullDataUse_address_acc',fk);
        },
        updateUserFullDataUse_address_dev({ commit }, fk)
        {
            commit('updateFullDataUse_address_dev',fk);
        },
        updateUserFullDataLanguage_fk({ commit }, fk)
        {
            commit('updateFullDataLanguage_fk',fk);
        },
        updateUserFullDataTimeModel_fk({ commit }, fk)
        {
            commit('updateFullDataTimeModel_fk',fk);
        },
        updateUserFullDataBankAccount_fk({ commit }, fk)
        {
            commit('updateUserFullDataBankAccount_fk',fk);
        },
        updateUserFullDataTime_start_saldo({ commit }, saldo)
        {
            commit('updateFullDataTime_start_saldo',saldo);
        },
        
    },
    mutations: {
        getUserMinRequest(state) {
            state.usermin = {firstName:"",secondName:"",lastName:"",titel:"",email:"",email2:"",user_Img_URL:"",user_img:"",partnerID:"",language_fk:1,userGuid:""};
            state.status = { loading: true,ready: false  };
        },
        getUserMinSuccess(state, userMinData) {
            state.usermin = userMinData;
            state.status = { loading: false,ready: true };
        },
        getUserMinFail(state) {
            state.usermin = {firstName:"",secondName:"",lastName:"",titel:"",email:"",email2:"",user_Img_URL:"",user_img:"",partnerID:"",language_fk:1,userGuid:""};
            state.status = { loading: false,ready: false };
        },
        getUserOverviewDataRequest(state,userOverviewData) {
            state.useroverview = userOverviewData;
            state.status = { loading: true,ready: false  };
        },
        getUserOverviewDataSuccess(state, userOverviewData) {
            state.useroverview= userOverviewData;
            state.status = { loading: false,ready: true };
        },
        getUserOverviewDataFail(state) {
            state.useroverview = null;
            state.status = { loading: false,ready: false };
        },
        getUserOverviewDataSingleSuccess(state, newData) {
            
            for(var idx= 0; idx < state.useroverview.length; idx++)
            {
                if(state.useroverview[idx].userGuid === newData.userGuid)
                {
                    state.useroverview[idx].blocked = newData.blocked;
                    state.useroverview[idx].firstName = newData.firstName;
                    state.useroverview[idx].lastName = newData.lastName;
                    state.useroverview[idx].email = newData.email;
                    state.useroverview[idx].email2 = newData.email2;
                    state.useroverview[idx].partnerID = newData.partnerID;
                    state.useroverview[idx].user_Img_URL = newData.user_Img_URL;
                    state.useroverview[idx].user_img = newData.user_img;
                    break;
                }
            }
            
            state.status = { loading: false,ready: true };
        },
        getUserFullDataRequest(state)
        {
            state.userfull = {
                userdata:null,
                userdataOld:"",
                status:{ loading: true,ready: false }
            };
        },
        getUserFullDataSuccess(state, data)
        {
            state.userfull = {
                userdata:data,
                userdataOld:JSON.stringify(data),
                status:{ loading: false,ready: true }
            };
        },
        getUserFullDataFail(state)
        {
            state.userfull = {
                userdata:null,
                userdataOld:"",
                status:{ loading: false,ready: false }
            };
        },
        clearUserFullData(state)
        {
            state.userfull.userdata.titel = "";            
            state.userfull.userdata.firstName = "";
            state.userfull.userdata.secondName = "";
            state.userfull.userdata.lastName = "";
            state.userfull.userdata.userName = "";
            state.userfull.userdata.email = "";
            state.userfull.userdata.email2 = "";
            state.userfull.userdata.partnerID = "";
            state.userfull.userdata.tel1 = "";
            state.userfull.userdata.tel2 = "";
            state.userfull.userdata.address1_fk = -1;
            state.userfull.userdata.address2_fk = -1;
            state.userfull.userdata.address_acc_fk = -1;
            state.userfull.userdata.address_dev_fk = -1;
            state.userfull.userdata.use_address2 = false;
            state.userfull.userdata.use_address_acc = false;
            state.userfull.userdata.use_address_dev = false;
            state.userfull.userdata.user_Img_URL = "";
            state.userfull.userdata.user_img = "";
            state.userfull.userdata.userguid = "";
            state.userfull.userdata.language_fk = 1;
            state.userfull.userdata.bank_account_fk = -1;
            state.userfull.userdata.time_model_fk = -1;
            state.userfull.userdata.time_start_saldo = 0;
        },
        updateUserFullDataRequest(state)
        {
            state.userfull.status.loading= true;
        },
        updateUserFullDataSuccess(state)
        {
            state.userfull.status.loading= false;
        },  
        updateUserFullDataFail(state)
        {
            state.userfull.status.loading= false;
        }, 
        newUserRequest(state)
        {
            state.userfull.status.loading= true;
        },
        newUserSuccess(state)
        {
            state.userfull.status.loading= false;
        },  
        newUserFail(state)
        {
            state.userfull.status.loading= false;
        },               
        updateFullDataFirstName(state, firstName)
        {
            state.userfull.userdata.firstName = firstName;
        },               
        updateFullDataSecondName(state, secondName)
        {
            state.userfull.userdata.secondName = secondName;
        },               
        updateFullDataLastName(state, lastName)
        {
            state.userfull.userdata.lastName = lastName;
        },               
        updateFullDataUserName(state, userName)
        {
            state.userfull.userdata.userName = userName;
        },               
        updateFullDataTitel(state, titel)
        {
            state.userfull.userdata.titel = titel;
        },               
        updateFullDataEmail(state, email)
        {
            state.userfull.userdata.email = email;
        }, 
        updateFullDataEmail2(state, email)
        {
            state.userfull.userdata.email2 = email;
        },              
        updateFullDataPartnerID(state, partnerID)
        {
            state.userfull.userdata.partnerID = partnerID;
        },               
        updateFullDataTel1(state, tel1)
        {
            state.userfull.userdata.tel1 = tel1;
        },               
        updateFullDataTel2(state, tel2)
        {
            state.userfull.userdata.tel2 = tel2;
        },               
        updateFullDataAddress1_fk(state, fk)
        {
            state.userfull.userdata.address1_fk = fk;
        },               
        updateFullDataAddress2_fk(state, fk)
        {
            state.userfull.userdata.address2_fk = fk;
        },               
        updateFullDataAddress_acc_fk(state, fk)
        {
            state.userfull.userdata.address_acc_fk = fk;
        },               
        updateFullDataAddress_dev_fk(state, fk)
        {
            state.userfull.userdata.address_dev_fk = fk;
        },               
        updateFullDataUse_address2(state, fk)
        {
            state.userfull.userdata.use_address2= fk;
        },               
        updateFullDataUse_address_acc(state, fk)
        {
            state.userfull.userdata.use_address_acc = fk;
        },               
        updateFullDataUse_address_dev(state, fk)
        {
            state.userfull.userdata.use_address_dev = fk;
        },               
        updateFullDataLanguage_fk(state, fk)
        {
            state.userfull.userdata.language_fk = fk;
        },
        updateFullDataTimeModel_fk(state, fk)
        {
            state.userfull.userdata.time_model_fk = fk;
        },
        updateFullDataTime_start_saldo(state, saldo)
        {
            state.userfull.userdata.time_start_saldo = saldo;
        },
        
        updateUserFullDataBankAccount_fk(state, fk)
        {
            state.userfull.userdata.bank_account_fk = fk;
        },
        getUserRolesRequest(state)
        {
            state.rights.status={ready:false,loading:true};
            state.rights.data=[];
        },
        getUserRolesSuccess(state,data)
        {
            state.rights.status={ready:true,loading:false};
            state.rights.data=data;
        },
        getUserRolesFail(state)
        {
            state.rights.status={ready:false,loading:false};
            state.rights.data=[];
        }

    }
}
