<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddDebitor"
      :is-valid="formValid"
      style="z-index:30002">
       <div>
        <div>
          <p>{{$t('ADD_DEBITOR.HEADER.TEXT')}}</p>
          <div class="default-input d-flex align-items-center">{{$t('ADD_DEBITOR.DEBITOR_NUMBERGROUP')}}: 
            <vs-select :disabled="debitor_nr.length > 0" v-model="numbergroup_fk" class="ml-2">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbergroups" />
            </vs-select>
          </div>
          <vs-tabs v-model="activeTab">
            <vs-tab :label="$t('ADD_DEBITOR.TAB.NAMES')">
              <vs-divider >{{$t('ADD_DEBITOR.DIVIDER.COMPANY')}}</vs-divider>
              <div class="default-input clearfix align-items-center mb-3 mt-3">    
                <vs-checkbox class="float-left mr-3 inputx"  v-model="is_company">{{$t('COM.GENDER.COMPANY')}}</vs-checkbox>          
              </div>
              <div  class="default-input clearfix align-items-center mb-3">
                <vs-input ref="company" :label="$t('ADD_DEBITOR.LABEL.COMPANY')" :disabled="!is_company" class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.COMPANY')"  v-model="company" />
                <vs-input :label="$t('ADD_DEBITOR.LABEL.UID')"  :disabled="!is_company" class="float-left inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.UID')"  v-model="uid" />
              </div>
              <vs-divider>{{$t('ADD_DEBITOR.DIVIDER.TITEL')}}</vs-divider>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input ref="title_pre" :label="$t('ADD_DEBITOR.LABEL.TITEL_PRE')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.TITEL_PRE')"  v-model="title_pre"  />
                <vs-input :label="$t('ADD_DEBITOR.LABEL.TITEL_POST')"  class="float-left inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.TITEL_POST')"  v-model="title_post" />
              </div>
              <vs-divider>{{$t('ADD_DEBITOR.DIVIDER.NAME')}}</vs-divider>
              <div class="clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_DEBITOR.LABEL.FIRSTNAME')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.FIRSTNAME')"  v-model="firstname" :danger="firstname_fail" val-icon-danger="clear"/>
                <vs-input :label="$t('ADD_DEBITOR.LABEL.SECONDNAME')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.SECONDNAME')" v-model="secondname" :danger="secondname_fail" val-icon-danger="clear" />
                <vs-input :label="$t('ADD_DEBITOR.LABEL.LASTNAME')"  class="float-left inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.LASTNAME')" v-model="lastname" :danger="lastname_fail" val-icon-danger="clear"/>
              </div>
              <vs-divider>{{$t('ADD_DEBITOR.DIVIDER.TEL_EMAIL')}}</vs-divider>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_DEBITOR.LABEL.TEL1')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.TEL1')"  v-model="tel1" :danger="tel1_fail" val-icon-danger="clear" :danger-text="$t('ADD_DEBITOR.TEL_FAIL')" />
                <vs-input :label="$t('ADD_DEBITOR.LABEL.TEL2')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.TEL2')"  v-model="tel2" :danger="tel2_fail" val-icon-danger="clear" :danger-text="$t('ADD_DEBITOR.TEL_FAIL')"/>
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_DEBITOR.LABEL.EMAIL')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.EMAIL')"  v-model="email" :danger="emailFail" val-icon-danger="clear" />
                <vs-input :label="$t('ADD_DEBITOR.LABEL.EMAIL2')"  class="float-left inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.EMAIL2')"  v-model="email2" :danger="email2Fail" val-icon-danger="clear" />
              </div>              
            </vs-tab>
            <vs-tab :label="$t('ADD_DEBITOR.RECHNUNGSADRESSE')">
                  <div class="default-input clearfix align-items-center mt-3 mb-3">
                    <vs-input ref="street1" :label="$t('ADD_DEBITOR.LABEL.STREET1')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.STREET1')"  v-model="street1" :danger="street1_fail" val-icon-danger="clear"/>
                    <vs-input :label="$t('ADD_DEBITOR.LABEL.STREET2')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.STREET2')"  v-model="street2" />
                  </div>
                  <div class="default-input clearfix align-items-center mb-3">
                    <vs-input :label="$t('ADD_DEBITOR.LABEL.PLZ')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.PLZ')"  v-model="plz" :danger="plz_fail" val-icon-danger="clear"  :danger-text="$t('ADD_DEBITOR.PLZ_FAIL')"/>
                    <vs-input :label="$t('ADD_DEBITOR.LABEL.CITY')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.CITY')"  v-model="city" :danger="city_fail" val-icon-danger="clear"/>
                  </div>
                  <div class="default-input clearfix align-items-center mb-3">
                    <vs-select :label="$t('ADD_DEBITOR.LABEL.COUNTRY')" v-model="country_fk" class="float-left" :danger="country_fk_fail">
                      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
                    </vs-select>
                  </div>
            </vs-tab>
            <vs-tab :label="this.$t('ADD_DEBITOR.LIEFERADRESSE')">  
                  <div class="default-input d-flex align-items-center mt-3 mb-3">
                    <vs-checkbox v-model="extraAddress">{{$t('ADD_DEBITOR.LABEL.EXTRA_ADDR')}}</vs-checkbox>              
                  </div>
                  <div class="default-input clearfix align-items-center mb-3">
                    <vs-input :disabled="!extraAddress" ref="street1_del" :label="$t('ADD_DEBITOR.LABEL.STREET1')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.STREET1')"  v-model="street1_del" :danger="street1_del_fail" val-icon-danger="clear"/>
                    <vs-input :disabled="!extraAddress" :label="$t('ADD_DEBITOR.LABEL.STREET2')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.STREET2')"  v-model="street2_del" />
                  </div>
                  <div class="default-input clearfix align-items-center mb-3">
                    <vs-input :disabled="!extraAddress" :label="$t('ADD_DEBITOR.LABEL.PLZ')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.PLZ')"  v-model="plz_del" :danger="plz_del_fail" val-icon-danger="clear"  :danger-text="$t('ADD_DEBITOR.PLZ_FAIL')" />
                    <vs-input :disabled="!extraAddress" :label="$t('ADD_DEBITOR.LABEL.CITY')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_DEBITOR.LABEL.PLACEHOLDER.CITY')"  v-model="city_del" :danger="city_del_fail" val-icon-danger="clear"/>
                  </div>
                  <div class="default-input clearfix align-items-center mb-3">
                    <vs-select :disabled="!extraAddress" :label="$t('ADD_DEBITOR.LABEL.COUNTRY')" v-model="country_fk_del" class="float-left"  :danger="country_fk_del_fail">
                      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
                    </vs-select>
                  </div> 
            </vs-tab>   
          </vs-tabs>
        </div>
        <div class="d-flex">
          <vs-button v-if="activeTab > 0 " class="mr-3" type="filled" icon="arrow_back_ios" @click="back()">{{$t('COM.BACK')}}</vs-button>
          <vs-button v-if="activeTab < idLastTab" type="filled" icon="arrow_forward_ios" @click="next()">{{$t('COM.NEXT')}}</vs-button>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import {numbergroup_Types} from '../../../helper/enumHelper';

export default {
  name: "AddDebitorDlg",
  props:['InitData'],
  data: function (){
     return {
       id:0,
       numbergroups:[],
       numbergroup_fk:-1,
       numbergroupOk: false,
       debitor_nr:"",
       defaultNrGrpFk:-1,
       dlgActive: false,
       activeTab: 0,
       idLastTab: 2,
       title_pre:"",
       title_post:"",
       firstname:"",
       firstname_fail:false,
       firstname_ok:false,
       secondname:"",
       secondname_fail:false,
       lastname:"",
       lastname_fail:false,
       lastname_ok:false,
       is_company:false,
       uid:"",
       company:"",
       tel1:"",
       tel1_fail:false,
       tel1_ok:false,
       tel2:"",
       tel2_fail:false,
       email:"",
       emailOkay:true,
       emailFail:false,
       email2:"",
       email2Okay:true,
       email2Fail:false,
       street1:"",
       street1_fail:false,
       street1_ok:false,
       street2:"",
       plz:"",
       plz_fail:false,
       plz_ok:false,
       city:"",
       city_fail:false,
       city_ok:false,
       country_fk:-1,
       country_fk_fail:false,
       country_fk_ok:false,
       street1_del:"",
       street1_del_fail:false,
       street1_del_ok:false,
       street2_del:"",
       plz_del:"",
       plz_del_fail:false,
       plz_del_ok:false,
       city_del:"",
       city_del_fail:false,
       city_del_ok:false,
       country_fk_del:-1,   
       country_fk_del_fail:false,
       country_fk_del_ok:false,
       extraAddress:false,
       popupTitle: this.$t('ADD_DEBITOR.TITLE'),
       address_fk:0,
       address2_fk:0

     }
  }, 
  mounted () {
    this.$store.dispatch('numbergroup/getNumbergroupsByCompany',0,{ root: true });// Companyid des aktuellen users im backend ermitteln
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      next(){
        this.activeTab += 1;
      },
      back(){
        this.activeTab -= 1;
      },
      showDlg(){
        this.ClearForm();

          if(this.defaultNrGrpFk != -1)
          {
            this.numbergroup_fk = this.defaultNrGrpFk;
          }

        this.dlgActive=true;
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {

          this.id = data.debitor.id;
          this.firstname = data.debitor.firstname;
          this.secondname = data.debitor.secondname;
          this.lastname = data.debitor.lastname;
          this.firstname = data.debitor.firstname;
          this.title_pre = data.debitor.titel_pre;
          this.title_post = data.debitor.titel_post;
          this.m_f = data.debitor.male_female;
          this.uid = data.debitor.uid;
          this.company = data.debitor.company;
          this.tel1 = data.debitor.tel1;
          this.tel2 = data.debitor.tel2;
          this.email = data.debitor.email;
          this.email2 = data.debitor.email2;
          this.address_fk = data.debitor.address_fk;
          this.address2_fk = data.debitor.address2_fk;
          this.numbergroup_fk = data.debitor.numbergroup_fk;
          this.debitor_nr = data.debitor.debitor_nr;
          this.is_company = data.debitor.is_company;
          this.street1 = data.rech_adr.street1;
          this.street2 = data.rech_adr.street2;
          this.plz = data.rech_adr.plz;
          this.city = data.rech_adr.city;
          this.country_fk = data.rech_adr.country_fk;
          
          if(this.IsNotNull(data.lief_adr))
          {
            if(this.IsNotNull(data.lief_adr.street1))
            {
              if(data.lief_adr.street1.length > 0)
              {
                this.extraAddress = true;
                this.street1_del = data.lief_adr.street1;
                this.street2_del = data.lief_adr.street2;
                this.plz_del = data.lief_adr.plz;
                this.city_del = data.lief_adr.city;
                this.country_fk_del = data.lief_adr.country_fk;
              }
            }
          }
          
          this.popupTitle = this.$t('ADD_DEBITOR.TITLE.EDIT') + ' ('+this.debitor_nr+')';

          this.dlgActive=true;
        }
      },
      accepted(){

          var data = {
            debitor:{
              "id":this.id,
              "firstname":this.firstname,
              "secondname":this.secondname,
              "lastname":this.lastname,
              "titel_pre":this.title_pre,
              "titel_post":this.title_post,
              "male_female":this.m_f,
              "uid":this.uid,
              "company":this.company,
              "tel1":this.tel1,
              "tel2":this.tel2,
              "email":this.email,
              "email2":this.email2,
              "address_fk":this.address_fk,
              "address2_fk":this.address2_fk,
              "numbergroup_fk":this.numbergroup_fk,
              "is_company":this.is_company
              },
            rech_adr:
              {
                "street1":this.street1,
                "street2":this.street2,
                "plz":this.plz,
                "city":this.city,
                "country_fk":this.country_fk
              },
              lief_adr:
              {
                "street1":this.street1_del,
                "street2":this.street2_del,
                "plz":this.plz_del,
                "city":this.city_del,
                "country_fk":this.country_fk_del
              }
          };

          var name = this.firstname + ' ' + this.lastname;
          this.$store.dispatch('debitor/saveDebitor', data)
          .then(response => {
            if(response.success === true)
            {
                // Kundendropdownliste aktualisieren
                this.$store.dispatch('debitor/getMyDebitorsMin', { root: true },{ root: true }); 
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORS.SUCCESS.SAVE').replace("*1*",name)});  
            }
            else
            {
              var text = this.$t('DEBITORS.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            this.$emit('Accepted',response.id);
          }, 
          error =>{
              var text = this.$t('DEBITORS.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
              this.$emit('Accepted');
          });
          
          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      isNameFail(name,isMustHave){
        var fail = false;
        if(isMustHave)
        {
          if(name.length > 0)
          {
              if(!this.ContainNoNumbers(name))
              {
                // Name enthält Zahlen
                fail = true;
              }
          }
          else
          {
            fail = true;
          }
        }
        else
        {
         fail = !this.ContainNoNumbers(name);
        }
        return fail;
      },
      ClearForm(){
        this.id = 0;
        this.numbergroup_fk = -1;
        this.numbergroupOk = false;
        this.debitor_nr = "";
        this.activeTab =  0;
        this.title_pre = "";
        this.title_post = "";
        this.firstname = "";
        this.firstname_fail = false;
        this.firstname_ok = false;
        this.secondname = "";
        this.secondname_fail = false;
        this.lastname = "";
        this.lastname_fail = false;
        this.lastname_ok = false;
        this.is_company = false;
        this.uid = "";
        this.company = "";
        this.tel1 = "";
        this.tel1_fail = false;
        this.tel1_ok = false;
        this.tel2 = "";
        this.tel2_fail = false;
        this.email = "";
        this.emailOkay = true;
        this.emailFail = false;
        this.email2 = "";
        this.email2Okay = true;
        this.email2Fail = false;
        this.street1 = "";
        this.street1_fail = false;
        this.street1_ok = false;
        this.street2 = "";
        this.plz = "";
        this.plz_fail = false;
        this.plz_ok = false;
        this.city = "";
        this.city_fail = false;
        this.city_ok = false;
        this.country_fk = -1;
        this.country_fk_fail = false;
        this.country_fk_ok = true;// weil vorher schon ausgewählt
        this.street1_del = "";
        this.street1_del_fail = false;
        this.street1_del_ok = false;
        this.street2_del = "";
        this.plz_del = "";
        this.plz_del_fail = false;
        this.plz_del_ok = false;
        this.city_del = "";
        this.city_del_fail = false;
        this.city_del_ok = false;
        this.country_fk_del = -1;
        this.country_fk_del_fail = false;
        this.country_fk_del_ok = true;
        this.extraAddress = false;
        this.address_fk = 0;
        this.address2_fk = 0;

        if(this.countries.length > 1 )
        {
          this.country_fk = this.countries[1].value;
          this.country_fk_del = this.countries[1].value;
        }

        this.popupTitle = this.$t('ADD_DEBITOR.TITLE');
      }
  },
  computed:{
    countries(){
      return this.GetListOfCountriesForSelect(false);
    },
    formValid(){
        var valid = this.firstname_ok && this.lastname_ok && this.emailOkay && this.email2Okay && this.tel1_ok && this.street1_ok && this.plz_ok && this.city_ok && this.country_fk_ok && this.numbergroupOk;

        if(this.extraAddress == true)
        {
          valid = valid && this.street1_del_ok && this.plz_del_ok && this.city_del_ok && this.country_fk_del_ok;
        }

        return valid;
    },
    numbergroupsForSelect(){
      return this.GetListOfNumbergroupsForSelect(numbergroup_Types.KUNDE);
    },
  },
  watch:{
    activeTab(value){
      switch(value)
      {
        case 0:
          this.$nextTick(() => this.setFocusOnInput('title_pre'));
          break;
        case 1:
          this.$nextTick(() => this.setFocusOnInput('street1'));
          break;
        case 2:
          this.$nextTick(() => this.setFocusOnInput('street1_del'));
          break;          
      }
    },
    numbergroupsForSelect(value)
    {
        this.numbergroups = value.numbergroups;
        this.defaultNrGrpFk = value.default;
    },
    firstname(value)
    {
      this.firstname_fail = this.isNameFail(value,true);
      this.firstname_ok = !this.firstname_fail;
    },
    secondname(value)
    {
      this.secondname_fail = this.isNameFail(value,false);
    },
    lastname(value)
    {
      this.lastname_fail = this.isNameFail(value,true);
      this.lastname_ok = !this.lastname_fail && value.length > 0;
    },
    tel1(value)
    {
      this.tel1_fail = value.length == 0 || !this.ValidatePhone(value);
      this.tel1_ok = !this.tel1_fail;
    },
    tel2(value)
    {
      this.tel2_fail = value.length > 0  && !this.ValidatePhone(value);
    },
    email(value){

      this.emailOkay = this.ValidateEmail(value) || value == "";
      this.emailFail = !this.emailOkay;
    },
    email2(value){

      this.email2Okay = this.ValidateEmail(value) || value == "";
      this.email2Fail = !this.email2Okay;
    },
    street1(value){
      this.street1_fail = value.length == 0;
      this.street1_ok = !this.street1_fail;
    },
    plz(value){
      this.plz_fail = value.length == 0 || !this.ContainNumbersOnly(value);
      this.plz_ok = !this.plz_fail;
    },
    city(value){
      this.city_fail = value.length == 0;
      this.city_ok = !this.city_fail;
    },
    country_fk(value){
      this.country_fk_fail = value <= 0;
      this.country_fk_ok = !this.country_fk_fail;
    },
    street1_del(value){
      
      if(this.extraAddress == true)
      {
        this.street1_del_fail = value.length == 0;
        this.street1_del_ok = !this.street1_del_fail;
      }   
    },
    plz_del(value){
      
      if(this.extraAddress == true)
      {
        this.plz_del_fail = value.length == 0 || !this.ContainNumbersOnly(value);
        this.plz_del_ok = !this.plz_del_fail;
      }
    },
    city_del(value){
      if(this.extraAddress == true)
      {
        this.city_del_fail = value.length == 0;
        this.city_del_ok = !this.city_del_fail;
      } 
    },
    country_fk_del(value){
      if(this.extraAddress == true)
      {
        this.country_fk_del_fail = value <= 0;
        this.country_fk_del_ok = !this.country_fk_del_fail;
      }
    },
    numbergroup_fk(value)
    {
       this.numbergroupOk = value > 0
    },
    extraAddress(value)
    {
      if(value == false)
      {
        this.city_del_fail = false;
        this.city_del_ok = false;
        this.city_del = "";

        this.plz_del_fail = false;
        this.plz_del_ok = false;
        this.plz_del = "";

        this.street1_del_fail = false;
        this.street1_del_ok = false;
        this.street1_del = "";

        this.street2_del ="";
        this.country_fk_del = -1;
        this.country_fk_del_fail = false;
        this.country_fk_del_ok = false;

        if(this.address2_fk > 0)
        {
          this.$store.dispatch('debitor/deleteLieferAdr', this.address2_fk)
            .then(response => {
              if(response.success === true)
              {
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ADD_DEBITOR.SUCCESS.DELETE_LIEF')});  
              }
              else
              {
                var text = this.$t('ADD_DEBITOR.WARNING.DELETE_LIEF').replace("*1*",this.$t(response.text));
                this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
              }
              this.address2_fk = 0;
            }, 
            error =>{
                var text = this.$t('ADD_DEBITOR.ERROR.DELETE_LIEF').replace("*1*",error);
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
            });
        }
      }
      else
      {
        this.$nextTick(() => this.setFocusOnInput('street1_del'));
      }
    }
  }
};
</script>
<style>
div#promptAddDebitor > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddDebitor > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>