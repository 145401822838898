<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddPartner"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('ADD_PARTNER.HEADER.TEXT')}}</p>
          <div class="default-input d-flex align-items-center mb-2">{{$t('ADD_PARTNER.DEBITOR_NUMBERGROUP')}}: 
            <vs-select :disabled="debitor_nr.length > 0" v-model="numbergroup_fk" class="ml-2"  :danger="numbergroup_fk_fail" val-icon-danger="clear">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbergroups" />
            </vs-select>
          </div>
          <div class="default-input d-flex align-items-center mb-3">{{$t('ADD_PARTNER.PARENT_ID')}}: 
            <vs-select :disabled="debitor_nr.length > 0" v-model="companyGroup_fk" class="ml-2"  :danger="companyGroup_fk_fail" val-icon-danger="clear">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in companygroups" />
            </vs-select>
          </div>
          <vs-tabs v-model="activeTab">
            <vs-tab :label="$t('ADD_PARTNER.TAB.NAMES')">
              <vs-divider>{{$t('ADD_PARTNER.DIVIDER.TITEL')}}</vs-divider>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input ref="title_pre" :label="$t('ADD_PARTNER.LABEL.TITEL_PRE')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.TITEL_PRE')"  v-model="title_pre"  />
                <vs-input :label="$t('ADD_PARTNER.LABEL.TITEL_POST')"  class="float-left inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.TITEL_POST')"  v-model="title_post" />
              </div>
              <vs-divider>{{$t('ADD_PARTNER.DIVIDER.NAME')}}</vs-divider>
              <div class="clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_PARTNER.LABEL.FIRSTNAME')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.FIRSTNAME')"  v-model="firstname" :danger="firstname_fail" val-icon-danger="clear"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.SECONDNAME')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.SECONDNAME')" v-model="secondname" :danger="secondname_fail" val-icon-danger="clear" />
                <vs-input :label="$t('ADD_PARTNER.LABEL.LASTNAME')"  class="float-left inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.LASTNAME')" v-model="lastname" :danger="lastname_fail" val-icon-danger="clear"/>
              </div>
              <vs-divider>{{$t('ADD_PARTNER.DIVIDER.USERDATA')}}</vs-divider>
              <div class="clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_PARTNER.LABEL.USERNAME')"  class="float-left inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.USERNAME')" v-model="username" :danger="username_fail" val-icon-danger="clear"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.PARTNERID')"  class="float-left ml-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.PARTNERID')" v-model="partnerid" :danger="partnerid_fail" val-icon-danger="clear"/>
                <vs-button class="float-left mt-3 ml-1 inputx" icon="refresh" size="small" @click="GetNextPartnerId()"></vs-button>
                <div class="float-left ml-3 ">
                  <vs-select :label="$t('ADD_PARTNER.LABEL.SPRACHE')" v-model="language_fk" :danger="language_fk_fail">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in languages" />
                  </vs-select>
                </div>
              </div>
              <vs-divider>{{$t('ADD_PARTNER.DIVIDER.TEL_EMAIL')}}</vs-divider>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_PARTNER.LABEL.TEL1')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.TEL1')"  v-model="tel1" :danger="tel1_fail" val-icon-danger="clear" :danger-text="$t('ADD_PARTNER.TEL_FAIL')" />
                <vs-input :label="$t('ADD_PARTNER.LABEL.TEL2')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.TEL2')"  v-model="tel2" :danger="tel2_fail" val-icon-danger="clear" :danger-text="$t('ADD_PARTNER.TEL_FAIL')"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.EMAIL')"  class="float-left inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.EMAIL')"  v-model="email" :danger="emailFail" val-icon-danger="clear" />
              </div>
            </vs-tab>

            <vs-tab :label="$t('ADD_PARTNER.PARTNER_ADR')">
              <vs-divider>{{$t('ADD_PARTNER.DIVIDER.PARTNER_ADR')}}</vs-divider>
              <div class="default-input clearfix align-items-center mt-3 mb-3">
                <vs-input ref="street1" :label="$t('ADD_PARTNER.LABEL.STREET1')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.STREET1')"  v-model="street1" :danger="street1_fail" val-icon-danger="clear"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.STREET2')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.STREET2')"  v-model="street2" />
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_PARTNER.LABEL.PLZ')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.PLZ')"  v-model="plz" :danger="plz_fail" val-icon-danger="clear"  :danger-text="$t('ADD_PARTNER.PLZ_FAIL')"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.CITY')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.CITY')"  v-model="city" :danger="city_fail" val-icon-danger="clear"/>
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-select :label="$t('ADD_PARTNER.LABEL.COUNTRY')" v-model="country_fk" class="float-left" :danger="country_fk_fail">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
                </vs-select>
              </div>
              <vs-divider>{{$t('ADD_PARTNER.DIVIDER.PARTNER_ACC')}}</vs-divider>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_PARTNER.LABEL.PARTNER_ACC_NAME')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.PARTNER_ACC_NAME')"  v-model="acc_name" :danger="acc_name_fail" val-icon-danger="clear"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.PARTNER_ACC_BANKNAME')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.PARTNER_ACC_BANKNAME')"  v-model="acc_bank_name" :danger="acc_bank_name_fail" val-icon-danger="clear"/>
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_PARTNER.LABEL.PARTNER_ACC_IBAN')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.PARTNER_ACC_IBAN')"  v-model="iban" :danger="iban_fail" val-icon-danger="clear"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.PARTNER_ACC_BIC')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.PARTNER_ACC_BIC')"  v-model="bic" :danger="bic_fail" val-icon-danger="clear"/>
              </div>
            </vs-tab>

            <vs-tab :label="this.$t('ADD_PARTNER.COMPANY_DATA')">  
              <vs-divider >{{$t('ADD_PARTNER.DIVIDER.COMPANY')}}</vs-divider>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input ref="company" :label="$t('ADD_PARTNER.LABEL.COMPANY')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.COMPANY')"  v-model="comp_name" :danger="comp_name_fail" val-icon-danger="clear" />
                <vs-input :label="$t('ADD_PARTNER.LABEL.UID')"  class="float-left inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.UID')"  v-model="comp_uid" :danger="comp_uid_fail" val-icon-danger="clear" />
              </div>
              <div class="default-input clearfix align-items-center mt-3 mb-3">
                <vs-input ref="street1" :label="$t('ADD_PARTNER.LABEL.STREET1')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.STREET1')"  v-model="comp_street1" :danger="comp_street1_fail" val-icon-danger="clear"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.STREET2')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.STREET2')"  v-model="comp_street2" />
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_PARTNER.LABEL.PLZ')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.PLZ')"  v-model="comp_plz" :danger="comp_plz_fail" val-icon-danger="clear"  :danger-text="$t('ADD_PARTNER.PLZ_FAIL')"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.CITY')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.CITY')"  v-model="comp_city" :danger="comp_city_fail" val-icon-danger="clear"/>
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <div class="float-left">
                  <vs-select :label="$t('ADD_PARTNER.LABEL.COUNTRY')" v-model="comp_country_fk" class="float-left" :danger="comp_country_fk_fail">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
                  </vs-select>
                </div>
                <vs-button class="float-left ml-3 inputx" size="small" @click="GetDataFromPartner()">{{$t('ADD_PARTNER.FROM_PARTNER')}}</vs-button>
              </div>
              <vs-divider>{{$t('ADD_PARTNER.DIVIDER.TEL_EMAIL')}}</vs-divider>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_PARTNER.LABEL.TEL1')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.TEL1')"  v-model="comp_tel1" :danger="comp_tel1_fail" val-icon-danger="clear" :danger-text="$t('ADD_PARTNER.TEL_FAIL')" />
                <vs-input :label="$t('ADD_PARTNER.LABEL.TEL2')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.TEL2')"  v-model="comp_tel2" :danger="comp_tel2_fail" val-icon-danger="clear" :danger-text="$t('ADD_PARTNER.TEL_FAIL')"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.FAX')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.FAX')"  v-model="comp_fax" :danger="comp_fax_fail" val-icon-danger="clear" :danger-text="$t('ADD_PARTNER.TEL_FAIL')"/>
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_PARTNER.LABEL.EMAIL')"  class="float-left inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.EMAIL')"  v-model="comp_email" :danger="comp_email_fail" val-icon-danger="clear" />
                <vs-input :label="$t('ADD_PARTNER.LABEL.WEB')"  class="float-left inputx ml-3" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.WEB')"  v-model="comp_web" />
              </div>
              <vs-divider>{{$t('ADD_PARTNER.DIVIDER.PARTNER_ACC')}}</vs-divider>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_PARTNER.LABEL.PARTNER_ACC_NAME')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.PARTNER_ACC_NAME')"  v-model="comp_acc_name" :danger="comp_acc_name_fail" val-icon-danger="clear"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.PARTNER_ACC_BANKNAME')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.PARTNER_ACC_BANKNAME')"  v-model="comp_acc_bank_name" :danger="comp_acc_bank_name_fail" val-icon-danger="clear"/>
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <vs-input :label="$t('ADD_PARTNER.LABEL.PARTNER_ACC_IBAN')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.PARTNER_ACC_IBAN')"  v-model="comp_iban" :danger="comp_iban_fail" val-icon-danger="clear"/>
                <vs-input :label="$t('ADD_PARTNER.LABEL.PARTNER_ACC_BIC')"  class="float-left mr-3 inputx" :placeholder="$t('ADD_PARTNER.LABEL.PLACEHOLDER.PARTNER_ACC_BIC')"  v-model="comp_bic" :danger="comp_bic_fail" val-icon-danger="clear"/>
              </div>
            </vs-tab>   
          </vs-tabs>
        </div>
        <div class="d-flex">
          <vs-button v-if="activeTab > 0 " class="mr-3" type="filled" icon="arrow_back_ios" @click="back()">{{$t('COM.BACK')}}</vs-button>
          <vs-button v-if="activeTab < idLastTab" type="filled" icon="arrow_forward_ios" @click="next()">{{$t('COM.NEXT')}}</vs-button>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import {numbergroup_Types} from '../../../helper/enumHelper';

export default {
  name: "AddPartnerDlg",
  props:['InitData'],
  data: function (){
     return {
        id:0,
        numbergroups:[],
        numbergroup_fk:-1,
        debitor_nr:"",
        defaultNrGrpFk:-1,
        dlgActive: false,
        activeTab: 0,
        idLastTab: 2,
        title_pre:"",
        title_post:"",
        firstname:"",
        firstname_fail:false,
        firstname_ok:false,
        secondname:"",
        secondname_fail:false,
        lastname:"",
        lastname_fail:false,
        lastname_ok:false,
        is_company:true,
        tel1:"",
        tel1_fail:false,
        tel1_ok:false,
        tel2:"",
        tel2_fail:false,
        email:"",
        emailOkay:false,
        emailFail:false,
        street1:"",
        street1_fail:false,
        street1_ok:false,
        street2:"",
        plz:"",
        plz_fail:false,
        plz_ok:false,
        city:"",
        city_fail:false,
        city_ok:false,
        country_fk:-1,
        country_fk_fail:false,
        country_fk_ok:false,
        extraAddress:false,
        popupTitle: this.$t('ADD_PARTNER.TITLE'),
        username:"",
        username_fail:false,
        username_ok:false,
        partnerid:"",
        partnerid_fail:false,
        partnerid_ok:false,
        language_fk:-1,
        language_fk_fail:false,
        language_fk_ok:false,
        comp_name:"",
        comp_name_fail:false,
        comp_name_ok:false,
        comp_tel1:"",
        comp_tel1_fail:false,
        comp_tel1_ok:false,
        comp_tel2:"",
        comp_tel2_fail:false,
        comp_tel2_ok:false,
        comp_fax:"",
        comp_fax_fail:false,
        comp_fax_ok:false,
        comp_email:"",
        comp_email_fail:false,
        comp_email_ok:false,
        comp_web:"",
        comp_uid:"",
        comp_uid_fail:false,
        comp_uid_ok:false,
        comp_street1:"",
        comp_street1_fail:false,
        comp_street1_ok:false,
        comp_street2:"",
        comp_plz:"",
        comp_plz_fail:false,
        comp_plz_ok:false,
        comp_city:"",
        comp_city_fail:false,
        comp_city_ok:false,
        comp_country_fk:-1,
        comp_country_fk_fail:false,
        comp_country_fk_ok:false,
        acc_name:"",
        acc_name_fail:false,
        acc_name_ok:false,
        acc_bank_name:"",
        acc_bank_name_fail:false,
        acc_bank_name_ok:false,
        iban:"",
        iban_fail:false,
        iban_ok:false,
        bic:"",
        bic_fail:false,
        bic_ok:false,
        comp_acc_name:"",
        comp_acc_name_fail:false,
        comp_acc_name_ok:false,
        comp_acc_bank_name:"",
        comp_acc_bank_name_fail:false,
        comp_acc_bank_name_ok:false,
        comp_iban:"",
        comp_iban_fail:false,
        comp_iban_ok:false,
        comp_bic:"",
        comp_bic_fail:false,
        comp_bic_ok:false,
        numbergroup_fk_fail:false,
        numbergroup_fk_ok:false,
        companyGroup_fk:-1,
        companyGroup_fk_fail:false,
        companyGroup_fk_ok:false

     }
  }, 
  mounted () {
    this.$store.dispatch('numbergroup/getNumbergroupsByCompany',0,{ root: true });// Companyid des aktuellen users im backend ermitteln
    this.$store.dispatch('companygroup/getCompanyGroups', { root: true },{ root: true });
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      next(){
        this.activeTab += 1;
      },
      back(){
        this.activeTab -= 1;
      },
      GetDataFromPartner(){
        this.comp_street1 = this.street1;
        this.comp_plz = this.plz;
        this.comp_city = this.city;
        this.comp_country_fk = this.country_fk;
        this.comp_tel1 = this.tel1;
        this.comp_tel2 = this.tel2;
        this.comp_email = this.email;
        this.comp_acc_name = this.acc_name;
        this.comp_acc_bank_name = this.acc_bank_name;
        this.comp_iban = this.iban;
        this.comp_bic = this.bic;
      },
      ShowDlg(){
        this.ClearForm();

          if(this.defaultNrGrpFk != -1)
          {
            this.numbergroup_fk = this.defaultNrGrpFk;
          }

        this.dlgActive=true;
      },
      closed(){
        this.ClearForm();
      },

      accepted(){

          var data = {
            userdata:{
                user:{
                  firstName:this.firstname,
                  secondName:this.secondname,
                  lastName:this.lastname,
                  email:this.email,
                  titel:this.title_pre,
                  tel1:this.tel1,
                  tel2:this.tel2,
                  language_fk:this.language_fk,
                  userName:this.username,
                  partnerID:this.partnerid
                },
                address:{
                  street1:this.street1,
                  street2:this.street2,
                  plz:this.plz,
                  city:this.city,
                  country_fk:this.country_fk,
                  bez:this.street1
                },
                bankAccount:{
                  name:this.acc_name,
                  bank_name:this.acc_bank_name,
                  iban:this.iban,
                  bic:this.bic,
                  name_owner:this.firstname + " " + this.lastname
                }
              },
            companydata:
              {
                company:{
                  name:this.comp_name,
                  tel1:this.comp_tel1,
                  tel2:this.comp_tel2,
                  fax:this.comp_fax,
                  email:this.comp_email,
                  web:this.comp_web,
                  uid:this.comp_uid,
                  slogan:"",
                  addressHeader:"",
                  vanue:"",
                  dvr:"",
                  fn:"",
                  ara:"",
                  era:"",
                  eori:"",
                  isProducer:false,
                  isHeadquater:false,
                  logo_filename:""
                },
                address:{
                  street1:this.comp_street1,
                  street2:this.comp_street2,
                  plz:this.comp_plz,
                  city:this.comp_city,
                  country_fk:this.comp_country_fk,
                  bez:this.street1
                },
                bankAccount:{
                  name:this.comp_acc_name,
                  bank_name:this.comp_acc_bank_name,
                  iban:this.comp_iban,
                  bic:this.comp_bic,
                  name_owner:this.comp_name
                }

              },
              companyGroup_fk:this.companyGroup_fk,
              debitor_numbergroup_fk:this.numbergroup_fk
          };

          var name = this.firstname + ' ' + this.lastname;
          this.$store.dispatch('users/newPartner', data)
          .then(response => {
            if(response.success === true)
            {
                // Kundendropdownliste aktualisieren
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ADD_PARTNER.SUCCESS.SAVE').replace("*1*",name)});  
            }
            else
            {
              var text = this.$t('ADD_PARTNER.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            this.$emit('Accepted',response.id);
          }, 
          error =>{
              var text = this.$t('ADD_PARTNER.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
              this.$emit('Accepted');
          });
          
          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      isNameFail(name,isMustHave){
        var fail = false;
        if(isMustHave)
        {
          if(name.length > 0)
          {
              if(!this.ContainNoNumbers(name))
              {
                // Name enthält Zahlen
                fail = true;
              }
          }
          else
          {
            fail = true;
          }
        }
        else
        {
         fail = !this.ContainNoNumbers(name);
        }
        return fail;
      },
      ClearForm(){
        this.id=0;
        this.numbergroup_fk = -1;
        this.debitor_nr = "";
        this.defaultNrGrpFk = -1;
        this.dlgActive =  false;
        this.activeTab =  0;
        this.idLastTab =  2;
        this.title_pre = "";
        this.title_post = "";
        this.firstname = "";
        this.firstname_fail = false;
        this.firstname_ok = false;
        this.secondname = "";
        this.secondname_fail = false;
        this.lastname = "";
        this.lastname_fail = false;
        this.lastname_ok = false;
        this.is_company = true;
        this.tel1 = "";
        this.tel1_fail = false;
        this.tel1_ok = false;
        this.tel2 = "";
        this.tel2_fail = false;
        this.email = "";
        this.emailOkay = false;
        this.emailFail = false;
        this.street1 = "";
        this.street1_fail = false;
        this.street1_ok = false;
        this.street2 = "";
        this.plz = "";
        this.plz_fail = false;
        this.plz_ok = false;
        this.city = "";
        this.city_fail = false;
        this.city_ok = false;
        this.country_fk = -1;
        this.country_fk_fail = false;
        this.country_fk_ok = true;
        this.extraAddress = false;
        this.popupTitle =  this.$t('ADD_PARTNER.TITLE');
        this.username = "";
        this.username_fail = false;
        this.username_ok = false;
        this.partnerid = "";
        this.partnerid_fail = false;
        this.partnerid_ok = false;
        this.language_fk = -1;
        this.language_fk_fail = false;
        this.language_fk_ok = false;
        this.comp_name = "";
        this.comp_name_fail = false;
        this.comp_name_ok = false;
        this.comp_tel1 = "";
        this.comp_tel1_fail = false;
        this.comp_tel1_ok = false;
        this.comp_tel2 = "";
        this.comp_tel2_fail = false;
        this.comp_tel2_ok = false;
        this.comp_fax = "";
        this.comp_fax_fail = false;
        this.comp_fax_ok = false;
        this.comp_email = "";
        this.comp_email_fail = false;
        this.comp_email_ok = false;
        this.comp_web = "";
        this.comp_uid = "";
        this.comp_uid_fail = false;
        this.comp_uid_ok = false;
        this.comp_street1 = "";
        this.comp_street1_fail = false;
        this.comp_street1_ok = false;
        this.comp_street2 = "";
        this.comp_plz = "";
        this.comp_plz_fail = false;
        this.comp_plz_ok = false;
        this.comp_city = "";
        this.comp_city_fail = false;
        this.comp_city_ok = false;
        this.comp_country_fk = -1;
        this.comp_country_fk_fail = false;
        this.comp_country_fk_ok = false;
        this.acc_name = "";
        this.acc_name_fail = false;
        this.acc_name_ok = false;
        this.acc_bank_name = "";
        this.acc_bank_name_fail = false;
        this.acc_bank_name_ok = false;
        this.iban = "";
        this.iban_fail = false;
        this.iban_ok = false;
        this.bic = "";
        this.bic_fail = false;
        this.bic_ok = false;
        this.comp_acc_name = "";
        this.comp_acc_name_fail = false;
        this.comp_acc_name_ok = false;
        this.comp_acc_bank_name = "";
        this.comp_acc_bank_name_fail = false;
        this.comp_acc_bank_name_ok = false;
        this.comp_iban = "";
        this.comp_iban_fail = false;
        this.comp_iban_ok = false;
        this.comp_bic = "";
        this.comp_bic_fail = false;
        this.comp_bic_ok = false;
        this.numbergroup_fk_fail = false;
        this.numbergroup_fk_ok = false;
        this.companyGroup_fk = -1;
        this.companyGroup_fk_fail = false;
        this.companyGroup_fk_ok = false;

        if(this.countries.length > 1 )
        {
          this.country_fk = this.countries[1].value;
        }

        this.popupTitle = this.$t('ADD_PARTNER.TITLE');
      },
      GetNextPartnerId()
      {
          this.$store.dispatch('users/getNextPartnerId', this.lastname)
          .then(response => {

            if(response.success === true)
            {
                this.partnerid = response.text;
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ADD_PARTNER.SUCCESS.NEXT_PARTNERID')});  
            }
            else
            {
              var text = this.$t('ADD_PARTNER.WARNING.NEXT_PARTNERID').replace("*1*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
          }, 
          error =>{
              var text = this.$t('ADD_PARTNER.ERROR.NEXT_PARTNERID').replace("*1*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
      }
  },
  computed:{
    countries(){
      return this.GetListOfCountriesForSelect(false);
    },
    formValid(){
        var valid = this.firstname_ok && this.lastname_ok && this.emailOkay && this.tel1_ok && this.street1_ok && this.plz_ok && this.city_ok && this.country_fk_ok && this.companyGroup_fk_ok
        && this.username_ok && this.partnerid_ok && this.acc_name_ok && this.acc_bank_name_ok && this.iban_ok && this.bic_ok && this.comp_name_ok && this.comp_iban_ok && this.comp_bic_ok && this.numbergroup_fk_ok
        && this.comp_tel1_ok && this.comp_email_ok && this.comp_uid_ok && this.comp_street1_ok && this.comp_city_ok && this.comp_plz_ok && this.comp_country_fk_ok && this.comp_acc_name_ok && this.comp_acc_bank_name_ok;

        return valid;
    },
    numbergroupsForSelect(){
      return this.GetListOfNumbergroupsForSelect(numbergroup_Types.KUNDE);
    },
    languages: function(){
      return this.GetListOfLanguagesForSelect();
    },
    companygroups(){
      return this.GetCompanyGroupsForSelect();
    }
    
  },
  watch:{
    activeTab(value){
      switch(value)
      {
        case 0:
          this.$nextTick(() => this.setFocusOnInput('title_pre'));
          break;
        case 1:
          this.$nextTick(() => this.setFocusOnInput('street1'));
          break; 
        case 2:
          this.$nextTick(() => this.setFocusOnInput('company'));
          break;         
      }
    },
    numbergroupsForSelect(value)
    {
        this.numbergroups = value.numbergroups;
        this.defaultNrGrpFk = value.default;
    },
    firstname(value,oldvalue)
    {
      this.firstname_fail = this.isNameFail(value,true);
      this.firstname_ok = !this.firstname_fail;

      var tmp = (oldvalue + "."+this.lastname).toLowerCase();

      if((this.username == tmp || this.username == "") && this.lastname != "")
      {
        this.username = value + "."+this.lastname;
      }
    },
    secondname(value)
    {
      this.secondname_fail = this.isNameFail(value,false);
    },
    lastname(value,oldvalue)
    {
      this.lastname_fail = this.isNameFail(value,true);
      this.lastname_ok = !this.lastname_fail && value.length > 0;

      var tmp = (this.firstname + "."+oldvalue).toLowerCase();

      if((this.username == tmp || this.username == "") && this.firstname != "")
      {
        this.username = (this.firstname + "."+value).toLowerCase();
      }
    },
    tel1(value)
    {
      this.tel1_fail = value.length == 0 || !this.ValidatePhone(value);
      this.tel1_ok = !this.tel1_fail;
    },
    tel2(value)
    {
      this.tel2_fail = value.length > 0  && !this.ValidatePhone(value);
    },
    email(value){

      this.emailOkay = this.ValidateEmail(value);
      this.emailFail = !this.emailOkay;
    },
    street1(value){
      this.street1_fail = value.length == 0;
      this.street1_ok = !this.street1_fail;
    },
    plz(value){
      this.plz_fail = value.length == 0 || !this.ContainNumbersOnly(value);
      this.plz_ok = !this.plz_fail;
    },
    city(value){
      this.city_fail = value.length == 0;
      this.city_ok = !this.city_fail;
    },
    country_fk(value){
      this.country_fk_fail = value <= 0;
      this.country_fk_ok = !this.country_fk_fail;
    },
    username(value){
      this.username_fail = value.length == 0;

      if(this.username_fail == false)
      {
        this.$store.dispatch('users/existsUsername', value)
          .then(response => {
            if(response.success === false)
            {
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.USERNAME_EXISTS')});  
                this.username_fail = true;
            }

            this.username_ok = !this.username_fail;
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.ERROR.USERNAME_EXISTS').replace("*1*",error)});  
              this.username_fail = true;
              this.username_ok = !this.username_fail;
          });
      }
      else
      {
        this.username_ok = !this.username_fail;
      }
      
    },
    partnerid(value){
      this.partnerid_fail = value.length == 0;
      this.partnerid_ok = !this.partnerid_fail;
    },
    language_fk(value){
      this.language_fk_fail = value <= 0;
      this.language_fk_ok = !this.language_fk_fail;
    },
    acc_name(value){
      this.acc_name_fail = value.length == 0;
      this.acc_name_ok = !this.acc_name_fail;
    },
    acc_bank_name(value){
      this.acc_bank_name_fail = value.length == 0;
      this.acc_bank_name_ok = !this.acc_bank_name_fail;
    },
    iban(value){
      this.iban_fail = value.length == 0;
      this.iban_ok = !this.iban_fail;
    },
    bic(value){
      this.bic_fail = value.length == 0;
      this.bic_ok = !this.bic_fail;
    },
    comp_name(value){
      this.comp_name_fail = value.length == 0;
      this.comp_name_ok = !this.comp_name_fail;
    },
    comp_uid(value){
      this.comp_uid_fail = value.length == 0;
      this.comp_uid_ok = !this.comp_uid_fail;
    },
    comp_tel1(value)
    {
      this.comp_tel1_fail = value.length == 0 || !this.ValidatePhone(value);
      this.comp_tel1_ok = !this.comp_tel1_fail;
    },
    comp_tel2(value)
    {
      this.comp_tel2_fail = value.length > 0  && !this.ValidatePhone(value);
      this.comp_tel2_ok = !this.comp_tel2_fail;
    },
    comp_fax(value)
    {
      this.comp_fax_fail = value.length > 0  && !this.ValidatePhone(value);
      this.comp_fax_ok = !this.comp_fax_fail;
    },
    comp_email(value){

      this.comp_email_ok = this.ValidateEmail(value);
      this.comp_email_fail = !this.comp_email_ok;
    },
    comp_street1(value){
      this.comp_street1_fail = value.length == 0;
      this.comp_street1_ok = !this.comp_street1_fail;
    },
    comp_plz(value){
      this.comp_plz_fail = value.length == 0 || !this.ContainNumbersOnly(value);
      this.comp_plz_ok = !this.comp_plz_fail;
    },
    comp_city(value){
      this.comp_city_fail = value.length == 0;
      this.comp_city_ok = !this.comp_city_fail;
    },
    comp_country_fk(value){
      this.comp_country_fk_fail = value <= 0;
      this.comp_country_fk_ok = !this.comp_country_fk_fail;
    },
    comp_acc_name(value){
      this.comp_acc_name_fail = value.length == 0;
      this.comp_acc_name_ok = !this.comp_acc_name_fail;
    },
    comp_acc_bank_name(value){
      this.comp_acc_bank_name_fail = value.length == 0;
      this.comp_acc_bank_name_ok = !this.comp_acc_bank_name_fail;
    },
    comp_iban(value){
      this.comp_iban_fail = value.length == 0;
      this.comp_iban_ok = !this.comp_iban_fail;
    },
    comp_bic(value){
      this.comp_bic_fail = value.length == 0;
      this.comp_bic_ok = !this.comp_bic_fail;
    },
    numbergroup_fk(value){
      this.numbergroup_fk_fail = value <= 0;
      this.numbergroup_fk_ok = !this.numbergroup_fk_fail;
    },
    companyGroup_fk(value){
      this.companyGroup_fk_fail = value <= 0;
      this.companyGroup_fk_ok = !this.companyGroup_fk_fail;
    },
  }
};
</script>
<style>
div#promptAddPartner > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddPartner > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>