<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex ">{{$t('PARTNERORDER.ACC_MEMBER.NR')}} {{partnerorder.order_nr}}
          <div class="ml-auto text-right btn-alignment">
            <vs-chip class="mr-3" :color="GetCommonStateColor(partnerorder.state)">{{GetCommonStateText(partnerorder.state)}}</vs-chip>
            <vs-chip class="mr-3" v-show="!pricesLoaded" color="primary">{{$t('PARTNERORDER.ACC_MEMBER.LOAD_PRICES')}}</vs-chip>
            <vs-chip class="mr-3" v-show="partnerorder.debitor_fk > 0" color="black">{{$t('PARTNERORDER.SEND_TO_DEBITOR_CHIP')}}</vs-chip>
            <PollingButtonDoc :DocumentType="docType" :InitDocId="partnerorder.document_fk" :LinkedFk="partnerorder.id" Icon="get_app" Size="small" Color="primary" Type="filled" ButtonClass="" ref="PartnerOrderPollingBtn" v-on:clicked="DownloadPartnerOrder"></PollingButtonDoc>
            <vs-button class="mr-3" size="small" color="primary" type="filled" icon="print" @click="PrintPartnerOrder()"></vs-button>
            <vs-button size="small" color="primary" type="filled" icon="refresh" @click="LoadData(partnerorder_fk)"></vs-button>
            <vs-button size="small" color="primary" type="filled" icon="close" @click="ClosePartnerOrder()"></vs-button>
          </div>
        </h2> 
        
        <div id="partnerordermembers" class="vs-con-loading__container" >
          
          <vs-textarea height="100px" counter="250" :label="$t('PARTNERORDER.ADD_ORDER.ZUSATZINFO')" v-model="partnerorder.infotext" :disabled="!pricesLoaded || !editable"/>
          <div v-if="partnerorder.debitor_fk > 0" class="d-flex align-items-center btn-alignment">
              <vs-select v-model="partnerorder.debitor_fk" class="float-right"  :disabled="!pricesLoaded || !editable || !partnerorder.dsgvoCheck" :label="$t('PARTNERORDER.DEBITOR')">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in debitors" />
              </vs-select>
          </div>
          <AddPartnerOrderMemberDlg ref="addPartnerOrderMemberDlg" v-on:Accepted="MemberAccepted"></AddPartnerOrderMemberDlg>
          <div class="d-flex align-items-center btn-alignment">
            <vs-button class="mt-3 mb-2 mr-2" :disabled="!pricesLoaded || !editable" type="filled" icon="add" @click="$refs.addPartnerOrderMemberDlg.ShowDlg(partnerorder.id,prices,partnerorder.country_fk,partnerorder.currency_fk)">{{$t('PARTNERORDER.MEMBER.ADD')}}</vs-button>
            <vs-button class="mt-3 mb-2 ml-2" :disabled="!pricesLoaded || !editable" type="filled" icon="save" @click="UpdatePartnerOrder()">{{$t('COM.SAVE')}}</vs-button>
          </div> 
          <div class="p-3">
            <drop-list
              :items="members" 
              @reorder="Reordered">
                <template v-slot:item="{item, reorder}">
                  <drag :key="item.id" :data="item" :disabled="!editable">
                    <div v-bind:class="{ leftPrimaryBorder: reorder }">
                      <AccountingMember :isOffer="false" :Editable="editable" :key="item.id" :Member="item" :PosNr="item.orderNr" v-on:Saved="RefreshSumBlock" v-on:EditClicked="EditAccountingMember" v-on:DeleteMember="DeletePartnerOrderMember"></AccountingMember>
                    </div>
                  </drag>
                </template>
                <template v-slot:inserting-drag-image="{data}">
                    <v-list-item-avatar style="transform:translate(-50%, -50%) scale(1.5)">
                        <img :src="data.avatar">
                    </v-list-item-avatar>
                </template>
                <template v-slot:reordering-drag-image/>
                <template v-slot:feedback="{data}">
                    <v-skeleton-loader
                            type="list-item-avatar-three-line"
                            :key="data.id"
                            style="border-left: 2px solid green; margin-left: -2px;"
                    />
                </template>
            </drop-list>
            <AccountingMemberSum ref="memberSum"></AccountingMemberSum>
          </div>
        </div>
        <div slot="footer" v-if="editable">
          <vs-row vs-justify="flex-end">
            <vs-button :disabled="members.length == 0" color="success" icon="send" @click="OpenConfirmSendPartnerOrder(partnerorder)">{{$t('PARTNERORDER.SEND')}}</vs-button>
          </vs-row>
        </div>
      </vs-card>
    </vs-col>
  </vs-row>
</div>
</template>
<script>

import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';
import enumHelper from '../../helper/enumHelper';
import downloadMethods from '../../helper/staticDownloadFuncHelper';
import AccountingMember from './AccountingMember';
import AccountingMemberSum from './AccountingMemberSum';
import AddPartnerOrderMemberDlg from './dialogs/AddPartnerOrderMemberDlg';
import {documentType} from "../../helper/enumHelper";
import {commonStates} from "../../helper/enumHelper";
import { Drag, DropList } from "vue-easy-dnd";
import PollingButtonDoc from './PollingButtonDoc.vue';

export default {
  name: "PartnerOrder",
  props:['InitData'],
  components:{
    AccountingMember,
    AddPartnerOrderMemberDlg,
    AccountingMemberSum,
    Drag,
    DropList,
    PollingButtonDoc
  },
  data: function (){
     return {
      partnerorder:{},
      prices:null,
      docType:documentType.PARTNERORDER,
      editable:true,
      partnerorder_fk:0,
      prevRoute:null,
      partnerorder_loaded:false
     }
  }, 
  mounted () 
  {
    this.partnerorder_fk = this.$route.params.partnerorder_fk;
    this.editable = this.$route.params.editable;
    this.$store.dispatch('productvariant/getProductvariants'); 
    this.$store.dispatch('productvariant/getProductvariantGroups'); 
   },
  beforeRouteEnter(to, from, next)
  {
    next(vm => {
      vm.prevRoute = from;
    })
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...downloadMethods,
      ...enumHelper,
      LoadData(id){

        if(!this.IsNotNull(id) && this.partnerorder_fk > 0)
        {
          id = this.partnerorder_fk;
        }
        
        if(this.$store.state.partnerorder.partnerorders.data == null)
        {

          this.$store.dispatch('partnerorder/getPartnerOrders'); 
        }
        
        this.$store.dispatch('partnerorder/getPartnerOrder',id)
        .then(response => {
          
          if(this.IsNotNull(response))
          {
            if(this.pricesLoaded == false)
            {
              this.$store.dispatch('pricelists/getPrices',{currency_fk:response.currency_fk,country_fk:response.country_fk,lastYear:false});
            }
            this.RefreshSumBlock();
          }
        }, 
        error =>{

            this.$vs.dialog({
              type:'alert',
              color: 'danger',
              title: this.$t('COM.ERROR.TITEL'),
              acceptText: this.$t('COM.OK'),
              text: error
            });
        });
      },
      Reordered(props)
      {
        // alert(props.from+" "+props.to);
        // https://github.com/rlemaigre/Easy-DnD

        // Änderung (from -> to) auf das member array anwenden
        props.apply(this.members);
        // Sortierung nachziehen
        var sort = {
          motherId: this.partnerorder.id,
          childs: []
        };

        for(var i=0; i < this.members.length; i++)
        {
          this.members[i].orderNr = i+1;
          var reorderMem = {
              id:this.members[i].id,
              orderNr:this.members[i].orderNr 
          }
          sort.childs.push(reorderMem);
        }

        this.UpdateOrderNr(sort);

      },
      UpdateOrderNr(data)
      {
        this.$store.dispatch('partnerorder/reorderPartnerOrderMember', data)
        .then(response => {
          if(response.success == true)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PARTNERORDER.ACC_MEMBER.SUCCESS.REORDER')});  
          }
          else
          {
            var text = this.$t('PARTNERORDER.ACC_MEMBER.WARNING.REORDER');
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERORDER.ACC_MEMBER.ERROR.REORDER') + ' ' +error});  
        });
      },
      EditAccountingMember(member)
      {
        this.$refs.addPartnerOrderMemberDlg.InitDlgData(this.prices,member,this.partnerorder.country_fk,this.partnerorder.currency_fk);
      },
      MemberAccepted(){
        this.LoadData();
      },
      RefreshSumBlock(){

        if(this.partnerorder != null)
        {
          
          this.$store.dispatch('partnerorder/getPartnerOrder',this.partnerorder_fk )
          .then(response => {
          
            if(this.IsNotNull(response))
            {
              this.$refs.memberSum.SetMembers(this.partnerorder.member);      
            }
          });
        }
      },
      DeletePartnerOrderMember(parameters){
        this.$store.dispatch('partnerorder/deletePartnerOrderMember', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PARTNERORDER.ACC_MEMBER.SUCCESS.DELETE').replace("*1*",parameters.bez)}); 
            this.LoadData(); 
          }
          else
          {
            var text = this.$t('PARTNERORDER.ACC_MEMBER.WARNING.DELETE').replace("*1*",parameters.bez);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERORDER.ACC_MEMBER.ERROR.DELETE').replace("*1*",parameters.bez) + ' ' +error});  
        });
      },
      ClosePartnerOrder(){
        
        var params = {id:this.partnerorder_fk,locked:false};

        this.$store.dispatch('partnerorder/lockPartnerOrder', params)
        .then(response => {
          if(response.success == true)
          {
              // Dahin zurück wo wir her gekommen sind
              if(this.IsNotNull(this.prevRoute))
              {
                this.$router.push({ name: this.prevRoute.name, params: this.prevRoute.params });
              }
              else
              {
                this.$router.go(-1);
              }
          }
        },
        error => {
          var text = this.$t('PARTNERORDER.LOCK_ORDER.ERROR').replace("*1*",error);
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
        });
      },
      DownloadPartnerOrder(actDocId)
      {
        if(this.IsNotNull(this.partnerorder))
        {
          var docId = actDocId;
          if(this.partnerorder.document_fk > 0 && this.partnerorder.document_fk != docId)
          {
            docId = this.partnerorder.document_fk;
          }
          else
          {
            this.partnerorder.document_fk = actDocId;
          }
          this.$store.dispatch('document/downloadDocument', docId)
              .then(response => {
                  this.DownloadWithFileSaver(response);
                }
              );
        }
      }, 
      PrintPartnerOrder()
      {
        var name = this.partnerorder.bez;
              this.$store.dispatch('document/createDocument', {id:this.partnerorder.id,name:this.partnerorder.order_nr, type: documentType.PARTNERORDER})
                .then(success => {
                  if(success === true)
                  {
                    this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PARTNERORDER.SUCCESS.PRINT').replace("*1*",name)});
                  }
                  else
                  {
                    this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERORDER.ERROR.PRINT').replace("*1*",name)});
                  }
                }, 
                error =>{
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERORDER.ERROR.PRINT').replace("*1*",name)+' '+error});
                }); 
      },
      UpdatePartnerOrder(){
        
        if(this.partnerorder_loaded == true)
        {
          var name = this.partnerorder.bez;
          this.$store.dispatch('partnerorder/savePartnerOrder', this.partnerorder)
          .then(response => {

            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PARTNERORDER.UPDATE_ORDER.SUCCESS.SAVE').replace("*1*",name)});  
                this.LoadData();
            }
            else
            {
              var text = this.$t('PARTNERORDER.UPDATE_ORDER.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              var text = this.$t('PARTNERORDER.UPDATE_ORDER.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
        }
      },

      OpenConfirmSendPartnerOrder(partnerorder) {
        this.$vs.dialog({
          type: "confirm",
          color: "success",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.SEND'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PARTNERORDER.QUESTION.SEND').replace("*1*",partnerorder.bez),
          accept: this.SendPartnerOrderRequest,
          parameters:{"partnerorder": partnerorder}
        });
      
      },
      SendPartnerOrderRequest: function(parameters)
      {
        parameters.partnerorder.state = commonStates.SENT;
        var name = parameters.partnerorder.bez;
        this.$store.dispatch('partnerorder/savePartnerOrder', parameters.partnerorder)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PARTNERORDER.ADD_ORDER.SUCCESS.SAVE').replace("*1*",name)});  
                this.$emit('Success',response.id);
                this.ClosePartnerOrder();
            }
            else
            {
              var text = this.$t('PARTNERORDER.ADD_ORDER.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              var text = this.$t('PARTNERORDER.ADD_ORDER.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
      }
  },
  computed:{
    members() {
      var data = [];
      if(this.$store.state.partnerorder.partnerorder.data != null)
      {
        data = this.$store.state.partnerorder.partnerorder.data.member;
      }
      return data;
    },
    debitors(){
      return this.GetListOfDebitorsForSelect();
    },    
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.partnerorder.partnerorder.status) != 'undefined')
        loading = this.$store.state.partnerorder.partnerorder.status.loading;
      return loading;
    },
    actPartnerOrder()
    {
      var data = {};
      if(this.$store.state.partnerorder.partnerorder.data != null)
      {
        data = this.$store.state.partnerorder.partnerorder.data;
      }
      return data;
    },
    actPrices(){
      var data = null;
      if(this.$store.state.pricelists.valid_pricelists.data != null)
      {
        data = this.$store.state.pricelists.valid_pricelists.data;
      }
      return data;
    },
    pricesLoaded()
    {
      if(this.prices != null)
        return true;
      else
        return false;
    },
  },
  watch:{
    actPrices(value){
      this.prices = value;
    },
    actPartnerOrder(value)
    {
      if(this.IsNotNull(value.bez))
      {
        this.partnerorder = value;
        this.partnerorder_loaded = true;
      }
      else
      {
        this.partnerorder_loaded = false;
      }
    },
    loadingTableData(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#partnerordermembers'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#partnerordermembers'});
      }
    },
    partnerorder_fk(value)
    {
      this.LoadData(value);
    }

  }
};
</script>