<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="10" vs-xs="10">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('TIMEREC.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData(0)" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('TIMEREC.HEADER.TEXT')}}</span>
        </p>
        <AddTimeRecDlg ref="addTimeRecDlg" v-on:Success="LoadData"></AddTimeRecDlg>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-xs="2">
      <vs-card>
        <vs-list>
          <template v-for="(item,index) in myHours">
            <vs-list-item :key="'var_'+index" :title="''+item.year" subtitle="erfasste Stunden:"><vs-chip>{{item.hours}}h {{item.mins}}min </vs-chip><vs-button  @click="LoadData(item.year)" class="ml-2 mr-2" size="small" color="primary" type="border" icon="sync"></vs-button></vs-list-item>
          </template>
        </vs-list>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddTimeRec">{{$t('TIMEREC.ADD')}}</vs-button>
            </div>
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  size = "small" type="filled" icon="add" @click="AddStd">{{$t('TIMEREC.ADD_STD')}}</vs-button>
              <vs-input size = "small" class="mr-3 inputx ml-2" :placeholder="$t('TIMEREC.PLACEHOLDER.STD_TEXT')"  v-model="stdText"/>
              
            </div> 
            <div id="timerecs_table_cont"  class="vs-con-loading__container" >
            
              <vs-tabs v-model="page">
                <template v-for="(month,index) in timesForTab">
                  <vs-tab :key="'m'+index" :label="month.name">
                    <vs-card >
                      <div class="card-title d-flex">
                        <h3>{{$t('TIMEREC.TIMEREC')}} {{month.name}}</h3>
                        <div class="ml-auto text-right">
                          
                          <vs-button  @click="PrintTimeRec(month)" class="ml-2" size="small" color="primary" type="filled" icon="print"></vs-button>  
                        </div>
                      </div>

                     
                      <vs-table
                        search
                        :data="month.recs"
                        :noDataText="$t('TIMEREC.NO_DATA')"
                        stripe
                        max-items="25" 
                        pagination
                        hoverFlat id="timerecs_table">

                        <template slot="header">
                          <h4>
                            Stunden erfasst: {{month.ist}} ({{month.saldo}})
                          </h4>
                        </template>
                        <template slot="thead">                               
                          <vs-th sort-key="start">
                            {{$t('TIMEREC.TABLE.COL1')}}
                          </vs-th>
                          <vs-th sort-key="end">
                            {{$t('TIMEREC.TABLE.COL2')}}
                          </vs-th>
                          <vs-th sort-key="diff">
                            {{$t('TIMEREC.TABLE.COL3')}}
                          </vs-th>
                          <vs-th sort-key="type">
                            {{$t('TIMEREC.TABLE.COL4')}}
                          </vs-th>  
                          <vs-th sort-key="description">
                            {{$t('TIMEREC.TABLE.COL5')}}
                          </vs-th>                                                                                                           
                          <vs-th></vs-th>                                                                                                                                             
                        </template>

                        <template slot-scope="{data}">
                          <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
                            <vs-td :data="tr.start">
                              {{DateToString(tr.start,false)}}
                            </vs-td>                  
                            <vs-td :data="tr.end">
                              {{DateToString(tr.end,false)}}
                            </vs-td>   
                            <vs-td :data="tr.diff">
                              {{tr.diff}}
                            </vs-td>   
                            <vs-td :data="tr.type">
                              {{GetTimeRecTypeText(tr.type)}}
                            </vs-td> 
                            <vs-td :data="tr.description">
                              {{tr.description}}
                            </vs-td>                                                                                                 
                            <vs-td>
                              <vs-button  @click="EditTimeRec(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                              <vs-button  @click="OpenConfirmDeleteTimeRec(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>      
                              <vs-button v-if="tr.type == enumTimeRecType && tr.diff_hours > 6" @click="AddPause(tr)" class="ml-3" size="small" color="warning" type="filled" icon="pause"></vs-button>                    
                            </vs-td>                    
                                                                                                                                                                                                      
                          </vs-tr>
                        </template>
                      </vs-table>
                    </vs-card>
                      <vs-row vs-justify="left">
                        <vs-col type="flex" vs-justify="left" vs-align="left" vs-lg="2" vs-sm="12" vs-xs="12">
                           <vs-card>
                            <h4 class="card-title d-flex">
                                {{$t('TIMEREC.SALDO_GES')}}
                            </h4>
                            <h3>{{month.saldo_all}}</h3>
                          </vs-card>
                        </vs-col>   
                        <vs-col type="flex" vs-justify="left" vs-align="left" vs-lg="2" vs-sm="12" vs-xs="12">
                           <vs-card>
                            <h4 class="card-title d-flex">
                                {{$t('TIMEREC.DIFF')}} ({{month.name}}):
                            </h4>
                            <h3 v-bind:class="{'color_danger':month.diff_hours < 0}">{{month.diff}}</h3>
                          </vs-card>
                        </vs-col>
                        <vs-col type="flex" vs-justify="left" vs-align="left" vs-lg="2" vs-sm="12" vs-xs="12">
                           <vs-card>
                            <h4 class="card-title d-flex">
                                {{$t('TIMEREC.VAC')}}
                            </h4>
                            <h3>{{month.vacation}}</h3>
                          </vs-card>
                        </vs-col>    
                        <vs-col type="flex" vs-justify="left" vs-align="left" vs-lg="2" vs-sm="12" vs-xs="12">
                           <vs-card>
                            <h4 class="card-title d-flex">
                                {{$t('TIMEREC.ILL')}}
                            </h4>
                            <h3>{{month.ill}}</h3>
                          </vs-card>
                        </vs-col>      
                        <vs-col type="flex" vs-justify="left" vs-align="left" vs-lg="2" vs-sm="12" vs-xs="12">
                           <vs-card>
                            <h4 class="card-title d-flex">
                                {{$t('TIMEREC.ZA')}}
                            </h4>
                            <h3>{{month.za}}</h3>
                          </vs-card>
                        </vs-col>                                                                
                      </vs-row>
                  </vs-tab>
                </template>
              </vs-tabs>   
            </div>     
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import enumHelper from '../helper/enumHelper';
import codeHelper from '../helper/staticCodeHelper';
import AddTimeRecDlg from './components/dialogs/AddTimeRecDlg.vue';
import {documentType} from "../helper/enumHelper";
import {timeRecType} from "../helper/enumHelper";

export default {
  name: "TimeRecs",
  components: {
    AddTimeRecDlg
  },
    data: function (){
      return {
      timerecs: [],
      year:this.GetActYear(),
      page:0,
      timesForTab: [{name:'nix',month:0,recs:[],soll:"",ist:"",vacation:"",za:"",ill:"",diff:"",soll_ist_proz:0,saldo:"",saldo_all:"",diff_hours:0}],
      stdText:"",
      sollist:[],
      enumTimeRecType: timeRecType.TIMEREC,
      myHours:[]
      };
  },
  mounted () {
      this.LoadData(0);
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.timerec.timerecs.data != null)
      {
        data = this.$store.state.timerec.timerecs.data
      }
      return data;
    },
    getMyHours(){
      var data = [];
        if(this.$store.state.timerec.recsByYear.data != null)
        {
          data = this.$store.state.timerec.recsByYear.data
        }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.timerec.timerecs.status) != 'undefined')
        loading = this.$store.state.timerec.timerecs.status.loading;
      return loading;
    },
    timeRecTypes(){
      return this.GetListOfDokumentTypeForSelect();
    },
    timesByMonth(){
      var months = [];
      for(var i = 12; i >= 1; i--)
      {
        var month = this.GetEmptyEntry();
        month.name= this.GetMonthName(i) + ', ' + this.year;
        month.month=i;

        this.timerecs.forEach(timerec => {
          var date = this.DateTimeStringToDate(timerec.start);
          if(date.getMonth()+1 == i)
          {
            month.recs.push(timerec);
          }
        });

        for(var s = 0; s < this.sollist.length; s++)
        {
          if(this.sollist[s].month == i && this.sollist[s].year == this.year)
          {
            month.soll = this.sollist[s].soll_txt;
            month.ist = this.sollist[s].ist_txt;
            month.diff = this.sollist[s].diff_txt;
            month.vacation = this.sollist[s].vacation_txt;
            month.ill = this.sollist[s].ill_txt;
            month.za = this.sollist[s].za_txt;
            month.soll_ist_proz = this.sollist[s].soll_ist_proz;
            month.saldo = this.sollist[s].saldo_txt;
            month.saldo_all = this.sollist[s].saldo_all_txt;
            month.diff_hours = this.sollist[s].diff;
            break;
          }
        }

        if(month.recs.length > 0)
        {
          months.push(month);
        }

      }
      if(months.length == 0)
      {
        months.push(this.GetEmptyEntry());
      }
      return months;
    }
  },
  watch: {
      getTableData(value) {
        if(this.IsNotNull(value.recs))
        {
          this.timerecs = value.recs;
        }
        else
        {
          this.timerecs = [];
        }

        if(this.IsNotNull(value.sollist))
        {
          this.sollist = value.sollist;
        }
        else
        {
          this.sollist = [];
        }
      },
      getMyHours(value) {
        if(this.IsNotNull(value.items))
        {
          this.myHours = value.items;
        }
        else
        {
          this.myHours = [];
        }
      },      
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#timerecs_table_cont'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#timerecs_table_cont'});
        }
      },
      timesByMonth(value){
        this.timesForTab = [this.GetEmptyEntry()];
        this.timesForTab = value;
      }
    },
  methods: {    
      ...helperMethods,
      ...enumHelper,
      ...codeHelper,
      LoadData: function (yearFromClick)
      {
         if(yearFromClick != 0)
         {
           this.year = yearFromClick;
         }

          this.timesForTab = [this.GetEmptyEntry()];
          this.$store.dispatch('users/getUserMinData', { root: true },{ root: true })
          .then(response => {
              if(this.IsNotNull(response))
              {
                this.$store.dispatch('timerec/getTimeRecs',this.year); 
                this.$store.dispatch('timerec/getTimeRecsStd', response.userGuid);
                this.$store.dispatch('timerec/getAllMyHoursByYear', { root: true },{ root: true })
              }
          });
         
      },
      GetEmptyEntry(){
        return {name:'nix',month:0,recs:[],soll:"",ist:"",vacation:"",za:"",ill:"",diff:""};
      },
      EditTimeRec(data){
        this.$refs.addTimeRecDlg.InitDlgData(data,false);
      },
      AddPause(data){
        this.$store.dispatch('timerec/addPause', data)
            .then(response => {
              if(response.success === true)
              {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TIMEREC.SUCCESS.ADD_PAUSE')});
              }
              else
              {
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TIMEREC.ERROR.ADD_PAUSE')});
              }
            }, 
            error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TIMEREC.ERROR.ADD_PAUSE')+' '+error});
            }); 
      },
      AddTimeRec(){
        this.$refs.addTimeRecDlg.ShowDlg(false);
      },
      PrintTimeRec(month){
        var name = this.$t('TIMEREC.PRINT_TITLE') +" " + month.name;
          this.$store.dispatch('document/createDocument', {id:Number(month.month * 10000 + this.year),name:name, type: documentType.TIMEREC,year:this.year, month:month.month, userguid:this.$store.state.users.usermin.userGuid})
            .then(success => {
              if(success == true)
              {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TIMEREC.SUCCESS.PRINT').replace("*1*",name)});
              }
              else
              {
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TIMEREC.ERROR.PRINT').replace("*1*",name)});
              }
            }, 
            error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TIMEREC.ERROR.PRINT').replace("*1*",name)+' '+error});
            }); 
      },
      GetActYear(){
        const d = new Date();
        return d.getFullYear();
      },
      OpenConfirmDeleteTimeRec(id) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('TIMEREC.QUESTION.DELETE'),
          accept: this.SendDeleteRequest,
          parameters:{"id": id}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('timerec/deleteTimeRec', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData(0);
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TIMEREC.SUCCESS.DELETE')});  
          }
          else
          {
            var text = this.$t('TIMEREC.WARNING.DELETE');
            text = text.replace("*1*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TIMEREC.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      AddStd(){

        this.$store.dispatch('timerec/insertTimeRecsFromStd',this.stdText)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TIMEREC.ADD_TIMEREC.SUCCESS.SAVE')});  
                this.LoadData(0);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('TIMEREC.ADD_TIMEREC.WARNING.SAVE').replace("*1*",text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TIMEREC.ADD_TIMEREC.ERROR.SAVE').replace("*1*",error)});  
          });

        
      }
  }
};

</script>
<style scoped>
#timerecs_table >div > div> table> tr> td {
  padding:1px 0px 1px 0px;
}
</style>